import Link from "next/link";
import styles from "./Kind.module.scss";

export default function ProductKind({
  kind,
  kind_subtype,
  custom_product_subtype,
}) {
  return (
    <>
      <Link
        href={`/shop/${kind}`}
        className={styles.type}
        onClick={(e) => e.stopPropagation()}
      >
        {kind}
      </Link>

      {/* if subtype doesn't exist or if type and subtype match, don't display subtype */}
      {/* {kind_subtype && kind_subtype.toLowerCase() !== kind.toLowerCase() ? (
        <Link href={`/shop/${kind}?q=${kind_subtype}`} className={styles.subtype} title={kind_subtype}>
          {kind_subtype}
        </Link>
      ) : null} */}
    </>
  );
}


// there's also root_subtype that are the subcategory filters