import { useState, useEffect, useContext } from "react";
import { useUser } from "../../context/user";
import StoreContext from "../../context/store";
import Heart from "../../components/icons/heart-solid.svg";
import Button from "../Button";

export default function FavoriteButton({ productId }) {
  const { userData, addFavorite, removeFavorite, isAuthenticated, localFavorites } = useUser();
  const { store } = useContext(StoreContext);
  const [isFavorite, setIsFavorite] = useState(false);

  // Sync isFavorite state with userData or localStorage
  useEffect(() => {
    if (isAuthenticated) {
      setIsFavorite(
        userData?.product_content?.product_favorites?.some(
          (fav) => fav.product_id === productId
        )
      );
    } else {
      const localFavorites = JSON.parse(localStorage.getItem("favorites") || "[]");
      setIsFavorite(localFavorites.some((fav) => fav.productId === productId));
    }
  }, [isAuthenticated, userData, localFavorites]);

  const toggleFavorite = async () => {
    if (isFavorite) {
      await removeFavorite(productId);
    } else {
      await addFavorite(productId, store);
    }
    setIsFavorite((prev) => !prev);
  };

  return (
    <Button
      type="button"
      onClick={toggleFavorite}
      className={isFavorite ? "favorite" : "not-favorite"}
    >
      <Heart />
    </Button>
  );
}
