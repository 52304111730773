// this needs to be rewritten to update toggle height when content changes (i.e. setting max-height will cause overflow when more content is added )

import { useState, useRef, useEffect } from "react";
import styles from "./Toggle.module.scss";

import Plus from '../../components/icons/plus.svg'
import Minus from '../../components/icons/minus.svg'
import Tag from '../../components/icons/tag.svg'

export default function Toggle(props) {
  const {title, tag, open, children } = props
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState();
  const [hidden, setHidden] = useState(false);
 
  const content = useRef();

  function handleClick() {
    setActive(!active);
    setHeight(active ? "0px" : `100%`);
  }

  useEffect(() => {
    if (open) {
      handleClick()
    } else {
      setActive(false);
      setHeight("0px");
    }
  }, [])

 return (
    <div className={`${styles.toggle} ${props.class ? props.class : ''}`}>
      <div className={styles.toggle__header} onClick={() => handleClick()}>
        <span className={styles.title}>{title}</span>
        {tag && <span className={styles.tag}><Tag /> {tag}</span>}
        <span className={styles.icon}>
          {active ? 
            (<Minus />) 
          : 
            (<Plus />)
          }
        </span>
      </div>
      <div
        className={`${styles.toggle__content}`}
        ref={content}
        style={{ maxHeight: `${height}` }}
      >
        {children}
      </div>
    </div>
  );
}

