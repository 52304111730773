// this handles the delivery location input. It uses google places for autocomplete and reverse geocoding to get the address for finding user location. It also fetches the store data from the zipcode and sets the store id in the store-context. 

import React, { useEffect, useRef, useState, useContext } from "react";
import styles from "./GooglePlaces.module.scss";
import LocationCrosshairs from "../icons/location-crosshairs.svg";
import LocationDot from "../icons/location-dot.svg";
import Xmark from "../icons/xmark.svg";
import ChevronDown from "../icons/chevron-down.svg";
import ChevronUp from "../icons/chevron-up.svg";
import Phone from "../icons/phone.svg";

import StoreContext from "../../context/store";
import { useShoppingCart } from "../../context/cart";
import { convertTo12HourFormat, deliveryTimeWithInterval } from "../../utils/formatTime";
import { useRouter } from "next/router";

import { reverseGeo } from "./reverseGeo";
import { Loader } from "@googlemaps/js-api-loader";

import Link from "next/link";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

const apiKey = process.env.NEXT_PUBLIC_GOOGLE_API;

const extractAddress = (place) => {

  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    lat: "",
    lng: "",
    street: "",
    plain() {
      const street_number = this.street_number ? this.street_number + " " : "";
      const route = this.route ? this.route + ", " : "";
      const city = this.city ? this.city + ", " : "";
      const state = this.state ? this.state + ", " : "";
      const zip = this.zip ? this.zip : "";
      return street_number + route + city + state + zip;
    },
    street() {
      const street_number = this.street_number ? this.street_number + " " : "";
      const route = this.route ? this.route : "";
      return street_number + route;
    }

  };


  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_1")) {
      address.state = value;
      address.stateCode = component.short_name;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }

    if (types.includes("street_number")) {
      address.street_number = value;
    }

    if (types.includes("route")) {
      address.route = value;
    }
  });

  if (place.geometry && place.geometry.location) {
    address.lat = place.geometry.location.lat();
    address.lng = place.geometry.location.lng();
  }

  return address;

};

export default function LocationSearch({ setModal, modal, hideButton }) {
  const searchInput = useRef(null);

  const { stores, deliveryZips, fetchStores, fetchDeliveryZips, store, setStore, setOrderType, setStoreName, setZipcode, orderType, address, setAddress } = useContext(StoreContext);
  const { setCartItems } = useShoppingCart();

  const [errorMessage, setErrorMessage] = useState();
  const [showDeliveryStore, setShowDeliveryStore] = useState(false);
  const [zipData, setZipData] = useState();
  const router = useRouter();

  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  useEffect(() => {
    if (stores) {
      fetchDeliveryZips();
    }
  }, [stores, fetchDeliveryZips]);

  // init gmap script. todo: move to utility file so a single instance can be run for multiple components
  const initMapScript = () => {
    // Create a new Loader instance
    const loader = new Loader({
      apiKey: apiKey,
      version: "weekly",
      libraries: ["places"],
    });

    return loader.load();
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  // init map script and autocomplete on modal open
  // todo: prevent from initial load for performance/request improvements
  useEffect(() => {
    if (modal || router.pathname === '/weed-delivery') {
      initMapScript().then(() => {
        initAutocomplete();
        console.log("autocomplete loaded")
      });
    }
  }, [modal, router.pathname]);

  // get geocoordinates, then reverse geocode to get address
  const findMyLocation = (e) => {
    e.preventDefault();
    formRef.current.reset();
    setAddress(null);
    setDeliveryStoreData(null);
    setShowDeliveryStore(false);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          searchInput.current.value = "Finding your location...";
          reverseGeo(position.coords.latitude, position.coords.longitude)
            .then((address) => {
              const _address = extractAddress(address);
              setAddress(_address);

              setTimeout(() => {
                searchInput.current.value = _address.plain();
              }, 300); // Delay for 1 second (1000 milliseconds)
            })
            .catch((error) => {
              console.log(error);
              setErrorMessage(
                "Could not fetch your location. Please try again."
              );
            });
        },
        (error) => {
          console.error("error: " + error);
          setErrorMessage("Could not fetch your location. Please try again.");
        }
      );
    } else {
      setErrorMessage("Your browser does not support geolocation.");
    }
  };

  // sett address on autocomplete change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  // delivery store data
  const [deliveryStoreData, setDeliveryStoreData] = useState();

  const fetchDeliveryStoreData = async (id) => {
    try {
      const response = await fetch(`/api/jane-store/${id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const json = await response.json();
      const store = json.data.store;
      setDeliveryStoreData(store);
      // Find matching zipcode in delivery store data from user address to get
      setZipData(address.zip && store.delivery_zipcodes.find((zip) => zip.zipcode === address.zip));
    } catch (error) {
      console.error('Error fetching delivery store data:', error);
    }
  };

  // user store data

  const onSubmit = (e) => {
    e.preventDefault();
    // todo:
    // check if submitted address exists in janeZips. set selectedZip to the matching zipcode object
    if (address?.zip && deliveryZips?.length > 0) {
      const zipcode = deliveryZips.map((zip) => zip.zipcode);
      if (zipcode.includes(address.zip)) {
        const selectedZip = deliveryZips.find((zip) => zip.zipcode === address.zip);
        const selectedStore = stores?.find(
          (store) => store.id === selectedZip.store_id
        );
        setErrorMessage(false);
        fetchDeliveryStoreData(selectedStore.id);
        setShowDeliveryStore(true);
        setAddress(address);
        setLocation(e)
        // setStore(selectedStore.id); //occurs in setLocation
        // console.log(address)
      } else if (!zipcode.includes(address.zip)) {
        setErrorMessage("Delivery is not available to that location");
      }
    } else setErrorMessage("Please select an address from the dropdown");
  };

  const setLocation = (e) => {
    if (address?.zip && deliveryZips?.length > 0) {
      const zipcode = deliveryZips.map((zip) => zip.zipcode);
      if (zipcode.includes(address.zip)) {
        const selectedZip = deliveryZips.find((zip) => zip.zipcode === address.zip);
        const selectedStore = stores?.find(
          (store) => store.id === selectedZip.store_id
        );
        setOrderType("Delivery");
        setStore(selectedStore.id);
        setStoreName(address.street() + ", " + address.city);
        setZipcode(address.zip);
        // clear cart if store changes
        {
          store !== selectedStore.id && setCartItems([]);
        }
        // toast(
        //   <div>
        //     <span className="fw-600">Delivery location updated: </span><br />{address.street() + ", " + address.city}
        //   </div>,
        //   {
        //     position: "bottom-center",
        //     autoClose: 2000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: true,
        //     transition: Slide,
        //     className: "location-toast",
        //   }
        // );
      } else return
    }
    else return
  };


  const formRef = useRef();

  const clearForm = (e) => {
    e.preventDefault();
    formRef.current.reset();
    setAddress(null);
    setDeliveryStoreData(null);
    setShowDeliveryStore(false);
  };
  const resetForm = () => {
    formRef.current.reset();
    setAddress(null);
    setDeliveryStoreData(null);
    setShowDeliveryStore(false);
  };


  // clear address and deliveryStoreData when orderType changes from 'delivery' to 'pickup'  
  // State to hold the previous value of orderType
  const [prevOrderType, setPrevOrderType] = useState(orderType);
  useEffect(() => {
    // Check if the orderType has changed specifically from 'delivery' to 'pickup'. If so, clear the address and deliveryStoreData/success message
    if (prevOrderType === "Delivery" && orderType === "Pickup") {
      setAddress(null);
      setDeliveryStoreData(null);
      setShowDeliveryStore(false);
    }

    // Update the previous orderType value
    setPrevOrderType(orderType);
  }, [orderType]);

  // hours
  // get today's date
  const getDayOfWeek = () => {
    const today = new Date();
    const options = { weekday: "long" }; // 'long' will give you the full day name

    return new Intl.DateTimeFormat("en-US", options).format(today);
  };

  const [dayOfWeek, setDayOfWeek] = useState("");

  useEffect(() => {
    const day = getDayOfWeek();
    setDayOfWeek(day);
  }, []);

  const [showAllHours, setShowAllHours] = useState(false);

  const viewHours = (e) => {
    e.preventDefault();
    setShowAllHours(!showAllHours);
  };


  return (
    <div className={`${styles.location}`}>


      <>
        <div style={{ display: showDeliveryStore ? "flex" : "none" }} className={styles.successContainer} ref={searchInput}>
          {showDeliveryStore && <SuccessMessage address={address} />}
          <div role="button" className="danger-link fs-300 flex align-center" onClick={() => resetForm()}>
            <Xmark />Change Address
          </div>
        </div>

        <div style={{ display: !showDeliveryStore ? "block" : "none" }}>
          <div className={styles.findMyLocation} onClick={findMyLocation}>
            <LocationCrosshairs />Use My Current Location
          </div>

          <form className={styles.search} ref={formRef} onSubmit={onSubmit}>
            <div className={styles.input}>
              <label htmlFor="address" />
              <span className="visually-hidden">Enter your delivery address</span>
              <input
                ref={searchInput}
                id="address"
                type="text"
                placeholder="Enter your delivery address"
                onClick={(e) => clearForm(e)}
              />
            </div>

            <div className={styles.submitButton}>
              <input
                className="button"
                data-type="primary"
                style={deliveryStoreData ? { display: "none" } : undefined}
                type="submit"
                value="Submit"
              ></input>
            </div>
          </form>

          <span className={styles.errorMessage}>
            {errorMessage && errorMessage}
          </span>
        </div>
      </>

      {!deliveryStoreData ? (
        showDeliveryStore && <div className="text-center">Loading Store Data...</div>
      ) : (
        <div>
          <div className="fs-300 fw-600 pb-1">Delivering From:</div>
          <div className={styles.deliveryDetails}>
            <StoreInfo deliveryStoreData={deliveryStoreData} />
            <DeliveryHours
              deliveryStoreData={deliveryStoreData}
              dayOfWeek={dayOfWeek}
              showAllHours={showAllHours}
              viewHours={viewHours}
              zipData={zipData}
            />
          </div>
          {hideButton ? null : (
            <button
              className="button mt-2 text-center w-100 flex center"
              data-type="primary"
              onClick={() => setModal(false)}
            >
              Continue Shopping
            </button>
          )}
        </div>
      )}
    </div>
  );
}


function SuccessMessage({ address }) {
  return (
    <span className="fw-bold">
      <div className="text-primary-300">Success!</div>
      <div className="fs-300 fw-400">Your delivery address has been set to: </div>
      <div className="fs-500 text-accent-300">
        {address.street_number} {address.route}, {address.city}
      </div>
    </span>
  );
}

function StoreInfo({ deliveryStoreData }) {
  return (
    <div className={styles.storeInfo}>
      <div>
        {/* <div className="fs-400 fw-bold">
          {deliveryStoreData.name}
        </div> */}
        <div className={styles.address}>
          <div style={{paddingTop:'2px'}}>
          <LocationDot />
          </div>
          <div>
            <div className="fs-300">{deliveryStoreData.address}</div>
            <div className="fs-300">{deliveryStoreData.city}, CA</div>
          </div>
        </div>
        <Link href={`tel:${deliveryStoreData.phone.replace(/\D/g, "")}`} className="link fs-300 flex align-center gap">
          <Phone />
          {deliveryStoreData.phone}
        </Link>
      </div>
    </div>
  );
}

function DeliveryHours({ deliveryStoreData, dayOfWeek, showAllHours, viewHours, zipData }) {

  return (
    <div className={styles.deliveryHours}>
      <div>

        <div className="hours">
          {deliveryStoreData.delivery_hours.map((i, index) => (
            (showAllHours || i.day.toLowerCase() === dayOfWeek.toLowerCase()) && (
              <DeliveryHour
                hourInfo={i}
                key={index}
                showAllHours={showAllHours}
                dayOfWeek={dayOfWeek}
                zipData={zipData}
              />
            )
          ))}
          <div className={styles.openEveryday}>
            Open everyday,<br /> including holidays.
          </div>
          <div className={styles.allHours} style={{ fontSize: '0.8975rem', textAlign: "right" }} role="button" onClick={viewHours}>
            {showAllHours ? "View Less" : "View all Hours"}
            {showAllHours ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>
      </div>
    </div>
  );
}

function DeliveryHour({ hourInfo, showAllHours, dayOfWeek, zipData }) {
  const lastCall = zipData.last_call_minutes;
  const deliveryEndTime = hourInfo.period.to ? deliveryTimeWithInterval(hourInfo.period.to, lastCall) : null;
  return (
    <div className={`day ${hourInfo.day.toLowerCase() === dayOfWeek.toLowerCase() && showAllHours ? "today" : ""}`}>
      <span>{hourInfo.day.substring(0, 3)}</span>
      <div>
        {hourInfo.period.from ? convertTo12HourFormat(hourInfo.period.from) : null}
        {" "}
        -
        {" "}
        {deliveryEndTime ? convertTo12HourFormat(deliveryEndTime) : null}
      </div>
    </div>
  );
}
