const navItems = {
  "navItems": [
    {
      "title": "home",
      "link": "/"
    },
    {
      "title": "shop",
      "link": "#",
      "links": [
        {
          title: "all products",
          link: "/shop"
        },
        {
          title: "deals & Specials",
          link: "/deals"
        },
        {
          title: "By brand",
          link: "/brands"
        },
        {
          title: "flower",
          link: "/shop/flower"
        },
        {
          title: "pre-roll",
          link: "/shop/pre-roll"
        },
        {
          title: "extract",
          link: "/shop/extract"
        },
        {
          title: "vape",
          link: "/shop/vape"
        },
        {
          title: "edible",
          link: "/shop/edible"
        },
        {
          title: "drinks",
          link: "/shop/edible:drinks"
        },
        {
          title: "tincture",
          link: "/shop/tincture"
        },
        {
          title: "topical",
          link: "/shop/topical"
        },
        {
          title: "gear",
          link: "/shop/gear"
        },
        {
          title: "merch",
          link: "/shop/merch"
        },
      ]
    },
    {
      "title": "pickup",
      "link": "/pickup"
    },
    {
      "title": "delivery",
      "link": "/weed-delivery"
    },
     {
      "title": "about",
      "link": "#",
      "links": [
        {
          "title": "about us",
          "link": "/about"
        },
        {
          "title": "our brands",
          "link": "/our-brands"
        },
        {
          "title": "our community",
          "link": "/our-community"
        },
        {
          "title": "blog",
          "link": "/blog"
        },
        {
          "title": "careers",
          "link": "/careers"
        },
      ]
    },
     {
      "title": "help",
      "link": "#",
      "links": [
        {
          "title": "FAQ",
          "link": "/faq"
        },
        {
          "title": "contact",
          "link": "/contact"
        },
        {
          "title": "How to order",
          "link": "/how-to-order"
        },
        {
          "title": "Pay methods",
          "link": "/payment-options"
        },
        {
          "title": "returns",
          "link": "/return-policy"
        },
        // {
        //   "title": "site map",
        //   "link": "/site-map"
        // }
      ]
    },
  ],


  // footer items
  "footerItems": [
    {
      "title": "shop",
      "links": [
        {
          "title": "shop all",
          "link": "/shop"
        },
        {
          "title": "deals",
          "link": "/deals"
        },
        {
          "title": "brands",
          "link": "/brands"
        },
        {
          "title": "pickup",
          "link": "/pickup"
        },
        {
          "title": "delivery",
          "link": "/weed-delivery"
        },
      ]
    },
     {
      "title": "About",
      "links": [
        {
          "title": "About us",
          "link": "/about"
        },
        {
          "title": "our brands",
          "link": "/our-brands"
        },
        {
          "title": "our community",
          "link": "/our-community"
        },
        {
          "title": "blog",
          "link": "/blog"
        },
        {
          "title": "careers",
          "link": "/careers"
        },
      ]
    },
     {
      "title": "help",
      "links": [
        {
          "title": "FAQ",
          "link": "/faq"
        },
        {
          "title": "contact",
          "link": "/contact"
        },
        {
          "title": "How to order",
          "link": "/how-to-order"
        },
        {
          "title": "Pay Methods",
          "link": "/payment-options"
        },
        {
          "title": "returns",
          "link": "/return-policy"
        },
      ],
      
    },
     {
      "title": "account",
      "links": [
        {
          "title": "login",
          "link": "/login"
        },
        {
          "title": "Account",
          "link": "/profile/account"
        },
        {
          "title": "Order History",
          "link": "/profile/orders"
        },
        {
          "title": "Favorites",
          "link": "/profile/favorites"
        },
        {
          "title": "Rewards",
          "link": "/profile/rewards"
        },
      ]
    }
  ],
  "legalItems": [
    {
      "title": "terms of use",
      "link": "/terms-of-use"
    },
    {
      "title": "privacy policy",
      "link": "/privacy-policy"
    },
  ]
};

export {navItems};