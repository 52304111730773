import '../styles/reset.scss';
import '../styles/globals.scss';
import '../styles/swiper.scss';
import '../styles/ais.scss';

import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { Inter } from 'next/font/google';

import { StoreProvider } from '../context/store';
import { CartProvider } from '../context/cart';
import { UserProvider } from '../context/user';
import { SpecialsProvider } from '../context/specials';
import useScrollRestoration from '../hooks/useScrollRestoration';
import Nav from '../components/nav';

// Dynamic Imports
const AgeGate = dynamic(() => import('../components/age-gate/age-gate-location'));
const Footer = dynamic(() => import('../components/footer'));

// Amplify Configuration
Amplify.configure({
  Auth: {
    Cognito: {
      region: 'us-west-1',
      userPoolClientId: '7r12he3ll17kedc0t8pq7hc5al',
      userPoolId: 'us-west-1_tv50QHqZj',
      loginWith: {
        oauth: {
          domain: 'janers.auth.us-west-1.amazoncognito.com',
          scopes: ['openid', 'email', 'profile'],
          redirectSignIn: ['http://localhost:3000/login', 'https://plpcsanjose.com/login', 'https://dev.plpcsanjose.com/login'],
          redirectSignOut: ['http://localhost:3000', 'https://plpcsanjose.com', 'https://dev.plpcsanjose.com'],
          responseType: 'token',
        },
      },
    },
  },
  ssr: true,
});


// Font Configuration
const myFont = Inter({ subsets: ['latin'], display: 'swap' });

// Routes to hide Nav/Footer
const hideFromRoutes = [
  "/old", "/downtown-grand-opening", "/cart", "/test/cart", "/test/icons"
];
const shouldHideNavAndFooter = (pathname) =>
  hideFromRoutes.includes(pathname) || pathname.startsWith("/receipt/");

export default function App({ Component, pageProps, router }) {
  const hide = shouldHideNavAndFooter(router.pathname);
  const [ageGate, setAgeGate] = useState(null);
  useScrollRestoration(router);

  // Initialize Age Gate
  useEffect(() => {
    const storedAgeGate = localStorage.getItem('ageGate');
    setAgeGate(storedAgeGate !== 'true');
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
      </Head>
      <Authenticator.Provider>
        <StoreProvider>
          <UserProvider>
            <SpecialsProvider>
              <CartProvider>
                <a href="#content" className="skip-to-main-content-link">
                  Skip to main content
                </a>
                <div id="content" className={`${myFont.className} ${hide ? '' : 'content-wrap'}`}>
                  {ageGate && <AgeGate router={router} ageGate={ageGate} setAgeGate={setAgeGate} />}
                  {!hide && <Nav />}
                  <Component {...pageProps} />
                </div>
                {!hide && <Footer fontClass={myFont.className} />}
                <DefaultSeo
                  openGraph={{
                    type: 'website',
                    locale: 'en_US',
                    url: process.env.NEXT_PUBLIC_SITE_URL,
                    siteName: 'Purple Lotus',
                  }}
                  twitter={{
                    handle: '@purplelotusca',
                    site: '@purplelotusca',
                    cardType: 'summary_large_image',
                  }}
                />
              </CartProvider>
            </SpecialsProvider>
          </UserProvider>
        </StoreProvider>
      </Authenticator.Provider>
    </>
  );
}
