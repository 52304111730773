// modal for location.js

import { useContext, useMemo, useState, useEffect } from "react";
import StoreContext from "../../context/store";
import Link from "next/link";
import styles from "./Modal.module.scss";
import GooglePlaces from "./GooglePlaces";
import SkeletonElement from "../skeletons/SkeletonElement";
import { LottieIcon } from "../Lottie/icon";
import { useShoppingCart } from "../../context/cart";
import { convertTo12HourFormat } from "../../utils/formatTime";
import deliveryTruck from "../../components/icons/animated/car.json";
import shoppingBags from "../../components/icons/animated/shopping-bags.json";
import Xmark from "../../components/icons/xmark.svg";
import ArrowRight from "../../components/icons/arrow-right.svg";
import LocationDot from "../../components/icons/location-dot.svg";
import Button from "../Button";

export default function LocationModal({ modal, setModal }) {
  const [tab, setTab] = useState();
  const { orderType } = useContext(StoreContext);
  const [hasRenderedModal, setHasRenderedModal] = useState(false);

  useMemo(() => {
    if (orderType == "Delivery") setTab(1);
    else if (orderType == "Pickup") setTab(2);
    else return;
  }, [orderType]);

  useEffect(() => {
    if (modal && !hasRenderedModal) {
      setHasRenderedModal(true);
    }
  }, [modal, hasRenderedModal]);

  return (
    <div className={modal ? styles.popupOpen : styles.popupClosed}>
      <Backdrop setModal={setModal} />
      {hasRenderedModal &&
        <div className={styles.popup}>
          <div className={styles.popup__inner}>
            <div className={styles.popup__header}>
              <span className={styles.popup__title}>
                How do you want to shop?
              </span>
              <Button
                className="icon xmark"
                type="button"
                ariaLabel="close location selector"
                onClick={() => setModal(false)}
              >
                <Xmark />
              </Button>
            </div>

            <div className={styles.tabs}>
              <Tab
                id={2}
                name="Pickup"
                tab={tab}
                setTab={setTab}
                icon={shoppingBags}
              />
              <Tab
                id={1}
                name="Delivery"
                tab={tab}
                setTab={setTab}
                icon={deliveryTruck}
              />
            </div>

            <div
              className={
                tab === 1
                  ? `${styles.tab__content} ${styles.active__content}`
                  : `${styles.tab__content}`
              }
            >
              <DeliveryOptions setModal={setModal} modal={modal} />
            </div>

            <div
              className={
                tab === 2
                  ? `${styles.tab__content} ${styles.active__content}`
                  : `${styles.tab__content}`
              }
            >
              <PickupLocations setModal={setModal} />
            </div>
          </div>
        </div>
      }
    </div>

  );
}

function Tab({ id, name, setTab, tab, icon }) {
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    setTab(id);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500);
  };

  return (
    <button
      className={
        tab === id
          ? `${styles.tab} ${styles.active} lottie-dark-hover`
          : `${styles.tab} ${animate ? "lottie-dark-hover" : "lottie-dark"}`
      }
      onClick={() => handleClick()}
    >
      <LottieIcon animatedIcon={icon} animate={animate} />
      <span>{name}</span>
    </button>
  );
}

function PickupLocations({ setModal }) {
  const { stores, fetchStores, store, setStore, setOrderType, setStoreName, setZipcode } = useContext(StoreContext);
  const [selectedPickupStore, setSelectedPickupStore] = useState();
  const [warningMessage, setWarningMessage] = useState(null);
  const { setCartItems } = useShoppingCart();


  useEffect(() => {
    fetchStores();
  }, [fetchStores]);

  // Filter stores to exclude 117 and 2100
  const filteredStores = useMemo(() => {
    return stores?.filter((store) => store.pickup && ![117, 2100].includes(store.id)) || [];
  }, [stores]);

  const handleStoreSelect = () => {
    if (!selectedPickupStore) {
      // Show warning if no store is selected
      setWarningMessage("Please select a store.");
      return;
    }

    // Clear warning if a store is selected
    setWarningMessage("");

    setStore(selectedPickupStore.id);
    setOrderType("Pickup");
    setStoreName(selectedPickupStore.full_address);
    setZipcode(selectedPickupStore.zip);
    setModal(false);

    // Empty cart and show toast if store changes
    if (store !== selectedPickupStore.id) {
      setCartItems([]);
      // toast(
      //   <div className="pr-200">
      //     <span className="fw-600">Store changed to Pickup:</span>
      //     <br />
      //     {selectedPickupStore.full_address}
      //   </div>,
      //   {
      //     position: "bottom-center",
      //     autoClose: 1500,
      //     hideProgressBar: false,
      //     pauseOnHover: true,
      //     draggable: true,
      //     className: "location-toast",
      //   }
      // );
    }
  };

  return (
    <>
      {filteredStores.length > 0 ? (
        filteredStores.map((pickupStore) => (
          <PickupLocation
            key={store.id}
            store={store.id}
            pickupStore={pickupStore}
            setSelectedPickupStore={setSelectedPickupStore}
            selectedPickupStore={selectedPickupStore}
            setWarningMessage={setWarningMessage}
          />
        ))
      ) : (
        <>
          <PickupLocationSkeleton />
          <PickupLocationSkeleton />
        </>
      )}
      {warningMessage && <div className={styles.warning}>{warningMessage}</div>}
      <Button type="button" className="primary w-100" onClick={handleStoreSelect}>Shop Pickup</Button>
    </>
  );
}

function PickupLocation({ store, pickupStore, selectedPickupStore, setSelectedPickupStore, setWarningMessage }) {
  const storeName = pickupStore?.name.replace("Purple Lotus - ", "").replace(/ Pickup$/, "");
  const [dayOfWeek, setDayOfWeek] = useState(getDayOfWeek());

  function getDayOfWeek() {
    const today = new Date();
    return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(today);
  }

  const isOpenNow = (from, to) => {
    const now = new Date();
    const [fromHours, fromMinutes] = from.split(":").map(Number);
    const [toHours, toMinutes] = to.split(":").map(Number);

    const fromTime = new Date();
    fromTime.setHours(fromHours, fromMinutes, 0);

    const toTime = new Date();
    toTime.setHours(toHours, toMinutes, 0);

    return now >= fromTime && now <= toTime;
  };

  const formatAddress = (fullAddress) => {
    if (!fullAddress) return "";
    const parts = fullAddress.split(", ");
    if (parts) {
      return (
        <>
          <div>{parts[0]}</div>
        </>
      );
    } else return fullAddress;
  };

  const handleClick = () => {
    setSelectedPickupStore(pickupStore);
    setWarningMessage(false);
  };

  return (
    <label className={`${styles.pickup_location} ${selectedPickupStore?.id === pickupStore?.id ? styles.selected : ""}`} onClick={handleClick}>

      <div className={styles.store}>
      <div className={styles.storeHeader}>
        <input
          type="radio"
          name="pickupStore"
          className={styles.radioInput}
        />
          <div className={styles.storeTitle}>{storeName}
            <div className={styles.address}>
              <LocationDot />
              <span>{formatAddress(pickupStore.full_address)}</span>
            </div>
          </div>
          </div>
          {pickupStore.hours?.pickup_hours?.map((i, index) => {
            const isToday = i.day.toLowerCase() === dayOfWeek.toLowerCase();
            if (isToday) {
              return (
                <div key={index} className={styles.hours}>
                  <div className={isOpenNow(i.period.from, i.period.to) ? styles.open : styles.closed}>
                    {isOpenNow(i.period.from, i.period.to) ? "Open Now" : "Closed"}
                  </div>
                  <div>
                    {convertTo12HourFormat(i.period.from)} -{" "}
                    {convertTo12HourFormat(i.period.to)}
                  </div>
                </div>
              );
            }
            return null;
          })}
 

      </div>
    </label>
  );
}




function DeliveryOptions({ setModal, modal }) {
  return (
    <div className={styles.delivery_locations}>
      <div className={styles.delivery_input}>
        <GooglePlaces setModal={setModal} modal={modal} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          href="/weed-delivery#map"
          onClick={() => {
            setModal(false);
          }}
          className=" fw-semi-bold color-neutral-600 fs-300 link flex gap align-center"
        >
          <span>
            View delivery map{" "}
          </span>
          <ArrowRight />
        </Link>
      </div>
    </div>
  );
}

function Backdrop({ setModal }) {
  return (
    <div className={styles.backdrop} onClick={() => setModal(false)}></div>
  );
}

function PickupLocationSkeleton() {
  return (
    <div className={styles.pickup_location}>
      <div className={styles.skeleton}>
        <SkeletonElement type="text" />
        <SkeletonElement type="text" />
        </div>
    </div>
  );
}

