import { useEffect, useState } from "react";
import Image from "next/image";

export default function FallbackImage({ src, category, root_subtype, lineage, ...rest }) {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const normalizeValue = (value) =>
    value?.toLowerCase().replace(/\s+/g, "").replace(/-/g, "");

  const getFallbackImage = () => {
    const normalizedCategory = normalizeValue(category);
    const normalizedSubtype = normalizeValue(root_subtype);
    const normalizedLineage = normalizeValue(lineage);

    const waxesSubtypes = ["liveresins", "diamonds", "wax"];

    // Handle flower category with lineage
    if (normalizedCategory === "flower" && normalizedLineage) {
      return `https://product-assets.iheartjane.com/default-photos/${normalizedCategory}/${normalizedLineage}.png`;
    }

    // Handle special cases
    if (waxesSubtypes.includes(normalizedSubtype)) {
      return `https://product-assets.iheartjane.com/default-photos/extract/waxes.png`;
    }
    if (normalizedSubtype === "rosin") {
      return `https://product-assets.iheartjane.com/default-photos/extract/rosins.png`;
    }
    if (normalizedSubtype === "ricksimpsonoil") {
      return `https://product-assets.iheartjane.com/default-photos/extract/ricksimpsonoilrso.png`;
    }

    // Handle other categories with subcategory
    if (normalizedCategory && normalizedSubtype) {
      return `https://product-assets.iheartjane.com/default-photos/${normalizedCategory}/${normalizedSubtype}.png`;
    }

    // Default placeholder
    return "/placeholder.png";
  };

  const fallbackImage = getFallbackImage();

  return (
    <Image
      {...rest}
      src={imgSrc || fallbackImage}
      onError={() => {
        if (imgSrc !== fallbackImage) {
          setImgSrc(fallbackImage);
        }
      }}
    />
  );
}
