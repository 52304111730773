import { useState, useRef, useEffect } from "react";
import { useRouter } from "next/router";
import styles from "./SecondaryNav.module.scss";

import Deals from "../../components/icons/category/deals.svg";
import Leaf from "../../components/icons/category/leaf.svg";
import Flower from "../../components/icons/category/flower.svg";
import Preroll from "../../components/icons/category/pre-roll.svg";
import Extract from "../../components/icons/category/extract.svg";
import Vape from "../../components/icons/category/vape.svg";
import Edible from "../../components/icons/category/edible.svg";
import Drinks from "../../components/icons/category/drinks.svg";
import Tincture from "../../components/icons/category/tincture.svg";
import Topical from "../../components/icons/category/topical.svg";
import Gear from "../../components/icons/category/gear.svg";
import Merch from "../../components/icons/category/merch.svg";
// import Rewards from "../../components/icons/category/award.svg";
import Brands from "../../components/icons/category/brands.svg";

import RetainQueryLink from "../../components/RetainQueryLink";


import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { Navigation, Pagination, FreeMode, Scrollbar } from "swiper";

export default function SecondaryNav() {
	return (
		<div className={`${styles.nav} ${styles.active}`}>
			<div style={{ position: "relative" }}>
				<NavLinks />
			</div>
		</div>
	);
}

function NavLinks() {
	const router = useRouter();
	const swiperRef = useRef(null);
	const [activeSlideIndex, setActiveSlideIndex] = useState(0);
	const normalizedAsPath = decodeURIComponent(router.asPath.split("?")[0])
		.toLowerCase()
		.split("/")
		.pop();
	const path = router.asPath;

	const noRouteSelected = !["/shop", "/deals", "/rewards", "/brands"].some(
		(route) => router.asPath.includes(route)
	);

	const categories = [
		{
			title: "Shop All",
			category: "shop",
			icon: <Leaf />,
			href: "/shop",
		},
		{
			title: "Deals",
			category: "deals",
			icon: <Deals />,
			href: "/deals",
		},
		// {
		// 	title: "Events",
		// 	category: "events",
		// 	icon: <Events />,
		// 	href: "/events",
		// },
		// {
		// 	title: "Rewards",
		// 	category: "rewards",
		// 	icon: <Rewards />,
		// 	href: "/rewards",
		// },
		{
			title: "Brands",
			category: "brands",
			icon: <Brands />,
			href: "/brands",
		},
		{
			title: "Flower",
			category: "flower",
			icon: <Flower />,
			href: "/shop/flower",
		},
		{
			title: "Pre-roll",
			category: "pre-roll",
			icon: <Preroll />,
			href: "/shop/pre-roll",
		},
		{
			title: "Extract",
			category: "extract",
			icon: <Extract />,
			href: "/shop/extract",
		},
		{
			title: "Vape",
			category: "vape",
			icon: <Vape />,
			href: "/shop/vape",
		},
		{
			title: "Edible",
			category: "edible",
			icon: <Edible />,
			href: "/shop/edible",
		},
		{
			title: "Drinks",
			category: "edible",
			icon: <Drinks />,
			href: "/shop/edible:drinks",
		},
		{
			title: "Tincture",
			category: "tincture",
			icon: <Tincture />,
			href: "/shop/tincture",
		},
		{
			title: "Topical",
			category: "topical",
			icon: <Topical />,
			href: "/shop/topical",
		},
		{
			title: "Gear",
			category: "gear",
			icon: <Gear />,
			href: "/shop/gear",
		},
		{
			title: "Merch",
			category: "merch",
			icon: <Merch />,
			href: "/shop/merch",
		},
	];

	// hover for dropdown
	const [isHovering, setIsHovering] = useState();

	// Handle slide click
	const handleSlideClick = (index) => {
		swiperRef.current?.slideTo(index - 1);
		setActiveSlideIndex(index); // Update active slide index
	};

	// Use useEffect to monitor changes to router.asPath and scroll to corresponding slide
	useEffect(() => {
		const index = categories.findIndex(
			(category) =>
				decodeURIComponent(category.href).toLowerCase() === normalizedAsPath
		);
		if (index !== -1 && router.asPath.includes(categories[index].href)) {
			swiperRef.current?.slideTo(index);
			setActiveSlideIndex(index);
		}
	}, [router.asPath]);

	return (
		<>
			<Swiper
				modules={[Navigation, Pagination, FreeMode, Scrollbar]}
				className={styles.categories}
				spaceBetween={0}
				touchEventsTarget="wrapper"
				slidesPerView={"auto"}
				// slidesPerGroup={1}
				freeMode={true}
				navigation={{
					prevEl: `#nav-prev`,
					nextEl: `#nav-next`,
					clickable: true,
				}}
				// centeredSlides={true}
				onSwiper={(swiper) => (swiperRef.current = swiper)}
				initialSlide={activeSlideIndex}
			>
				{categories.map((category, index) => {
					const normalizedCategoryHref = decodeURIComponent(
						category.href
					).toLowerCase();

					return (
						<SwiperSlide
							className={`${styles.slide} ${path === category.href ?  styles.active : null}`}
							key={index}
							onMouseOver={() => setIsHovering(category.title)}
							onMouseOut={() => setIsHovering(false)}
							onClick={() => {
								handleSlideClick(index);
								setIsHovering(false);
							}}
						>
							<RetainQueryLink
								href={category.href}
								className={`${styles.category}`}
								key={index}
							>
								{category.href === "/shop" ? (
									<div
										className={`${styles.icon} ${path === category.href ? styles.activeIcon : null} ${noRouteSelected && styles.inactive}`}
									>
										{category.icon}
									</div>
								) : (
									<div
										className={`${styles.icon} ${path.startsWith(category.href) ? styles.activeIcon : null} ${noRouteSelected && styles.inactive}`}
									>
										{category.icon}
									</div>
								)}

								<div className={`${styles.category__title} `}>
									{category.title}
								</div>
							</RetainQueryLink>

							{/* <Dropdown rootType={category.title} isHovering={isHovering} /> */}
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	);
}
