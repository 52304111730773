import { createContext, useContext, useEffect, useState, useCallback, useRef } from "react";
import { fetchAuthSession, updatePassword, signIn, signOut } from "aws-amplify/auth";
import { useRouter } from "next/router";
import StoreContext from "../context/store";

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [errorUser, setErrorUser] = useState(null);

  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [errorOrders, setErrorOrders] = useState(null);

  const { store } = useContext(StoreContext);
  const [favoriteQuantity, setFavoriteQuantity] = useState(0);

  const router = useRouter();

  const isAuthenticated = !!userData;
  const userInitialized = useRef(false);  // Prevents premature redirects before initialization is complete

  // Helper function to fetch user data
  const fetchUserData = async () => {
    const session = await fetchAuthSession();
    const { accessToken } = session?.tokens ?? {};
    if (!accessToken) throw new Error("Access token not found");

    const response = await fetch('/api/jane-user', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) throw new Error(`Failed to fetch user data: ${response.statusText}`);
    const data = await response.json();
    return data?.data;
  };

  // Initialize user data on mount
  const initializeUser = async () => {
    try {
      const session = await fetchAuthSession();
      if (session?.tokens?.accessToken) {
        const userData = await fetchUserData();
        setUserData(userData);
      }
    } catch (error) {
      console.error("Error initializing user:", error);
      setUserData(null);
    } finally {
      setLoadingUser(false);
      userInitialized.current = true;
    }
  };

  useEffect(() => {
    if (!userInitialized.current) {
      initializeUser();
    }
  }, []);

  // Handle sign-in and fetch user data
  const handleSignIn = async (username, password) => {
    setLoadingUser(true);
    try {
      const { isSignedIn } = await signIn({ username, password });
  
      if (isSignedIn) {
        const userData = await fetchUserData();
        setUserData(userData);
  
        // Sync localStorage favorites with backend
        await syncFavorites(userData);
  
        return { success: true };
      } else {
        return { success: false, message: "Sign-in step required." };
      }
    } catch (error) {
      console.error("Error signing in:", error);
      return { success: false, message: error.message || "Failed to sign in." };
    } finally {
      setLoadingUser(false);
    }
  };

  // Handle sign-out
  const handleSignOut = async () => {
    setUserData(null);
    await signOut();
    router.replace("/login");
  };

  // Fetch all orders
  const fetchAllOrders = useCallback(async () => {
    setLoadingOrders(true);
    try {
      const { accessToken } = (await fetchAuthSession())?.tokens ?? {};
      if (!accessToken) throw new Error("Access token not found");

      const response = await fetch('/api/jane-orders', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error(`Failed to fetch orders: ${response.statusText}`);
      const data = await response.json();
      setOrders(data.orders || []);
    } catch (err) {
      console.error("Error fetching orders:", err);
      setErrorOrders(err.message);
    } finally {
      setLoadingOrders(false);
    }
  }, []);

  // Update password
  const handleUpdatePassword = async (oldPassword, newPassword) => {
    try {
      await updatePassword({ oldPassword, newPassword });
      return { success: true, message: "Password updated successfully." };
    } catch (error) {
      console.error("Error updating password:", error);
      return { success: false, message: error.message || "Failed to update password." };
    }
  };

  // Sync favorites
  const syncFavorites = async (userData) => {
    try {
      const session = await fetchAuthSession();
      const accessToken = session?.tokens?.accessToken;
  
      if (!accessToken) throw new Error("Unauthorized request. Please log in.");
  
      const localFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
      const backendFavorites = userData?.product_content?.product_favorites || [];
  
      // Convert backend favorites to a Set for easy lookup
      const backendFavoriteSet = new Set(
        backendFavorites.map(fav => `${fav.product_id}-${fav.store_id}`)
      );
  
      // Find missing favorites (exist in localStorage but not in backend)
      const missingFavorites = localFavorites.filter(
        fav => !backendFavoriteSet.has(`${fav.productId}-${fav.storeId}`)
      );
  
      if (missingFavorites.length === 0) {
        console.log("No new favorites to sync.");
        localStorage.removeItem("favorites"); // Clear local storage if fully synced
        return;
      }
  
      console.log("Syncing missing favorites:", missingFavorites);
  
      // Send missing favorites to the backend
      for (const fav of missingFavorites) {
        await fetch("/api/jane-favorites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            product_id: fav.productId,
            store_id: fav.storeId,
          }),
        });
      }
  
      // Fetch updated user data and update state
      const updatedUserData = await fetchUserData();
      setUserData(updatedUserData);
  
      // Clear localStorage after syncing
      localStorage.removeItem("favorites");
    } catch (error) {
      console.error("Error syncing favorites:", error);
    }
  };

  // favorite quantity 
  // const updateFavoriteQuantity = () => {
  //   if (!store) {
  //     setFavoriteQuantity(0);
  //     return;
  //   }
  
  //   let favorites = [];
  
  //   if (isAuthenticated && userData?.product_content?.product_favorites) {
  //     // Use authenticated user's favorites
  //     favorites = userData.product_content.product_favorites;
  //     console.log(favorites)
  //   } else {
  //     // Use local storage favorites for guests
  //     const localFavorites = JSON.parse(localStorage.getItem("favorites") || "[]");
  //     favorites = localFavorites;
  //   }
  
  //   // Filter favorites that match the selected store
  //   const filteredFavorites = favorites.filter(fav => fav.store_Id === store);
  
  //   setFavoriteQuantity(filteredFavorites.length);
  // };
  
  // useEffect(() => {
  //   updateFavoriteQuantity();
  //   console.log(favoriteQuantity);
  // }, [store, userData, isAuthenticated]);
  

  // add favorite product
  const addFavorite = async (productId, storeId) => {
    try {
      if (isAuthenticated) {
        // Add favorite via API for authenticated users
        const session = await fetchAuthSession();
        const accessToken = session?.tokens?.accessToken;
        if (!accessToken) throw new Error("Unauthorized request. Please log in.");
  
        const response = await fetch("/api/jane-favorites", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ product_id: productId, store_id: storeId }),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Failed to add favorite: ${errorData.error || response.statusText}`);
        }
  
        // Update user data after API call
        const updatedData = await fetchUserData();
        setUserData(updatedData);
      } else {
        // Handle local storage for guests
        let localFavorites = JSON.parse(localStorage.getItem("favorites") || "[]");
  
        // Prevent duplicates
        if (!localFavorites.some((fav) => fav.productId === productId)) {
          localFavorites.push({ productId, storeId });
          localStorage.setItem("favorites", JSON.stringify(localFavorites));
        }
      }
      // updateFavoriteQuantity();
    } catch (error) {
      console.error("Error adding favorite:", error);
    }
  };
  
  // remove favorite product
  const removeFavorite = async (productId) => {
    try {
      if (isAuthenticated) {
        // Remove favorite via API for authenticated users
        const session = await fetchAuthSession();
        const accessToken = session?.tokens?.accessToken;
        if (!accessToken) throw new Error("Unauthorized request. Please log in.");
  
        const response = await fetch(`/api/jane-favorites?favorite_id=${productId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Failed to remove favorite: ${errorData.error || response.statusText}`);
        }
  
        // Update user data after API call
        const updatedData = await fetchUserData();
        setUserData(updatedData);
      } else {
        // Handle local storage for guests
        let localFavorites = JSON.parse(localStorage.getItem("favorites") || "[]");
        localFavorites = localFavorites.filter((fav) => fav.productId !== productId);
        localStorage.setItem("favorites", JSON.stringify(localFavorites));
      }
      // updateFavoriteQuantity();
    } catch (error) {
      console.error("Error removing favorite:", error);
    }
  };
  
  // PATCH update user profile data
  const updateUserData = async (updatedData) => {
    try {
      const authSession = await fetchAuthSession();
      const { accessToken } = authSession?.tokens ?? {};
      const token = accessToken?.toString();

      if (!token) throw new Error('Access token not found');

      const response = await fetch('/api/jane-user', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) throw new Error(`Failed to update user data: ${response.statusText}`);

      const data = await response.json();
      setUserData(data?.data); // Update the context with the new data
    } catch (err) {
      console.error('Error updating user data:', err);
      setErrorUser(err.message);
    }
  };
  

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        userData,
        loadingUser,
        errorUser,
        orders,
        loadingOrders,
        errorOrders,
        fetchAllOrders,
        favoriteQuantity,
        addFavorite,
        removeFavorite,
        updatePassword: handleUpdatePassword,
        updateUserData,
        signIn: handleSignIn,
        signOut: handleSignOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  return context ?? {
    isAuthenticated: false,
    userData: null,
    loadingUser: true,
    errorUser: null,
    orders: [],
    loadingOrders: false,
    errorOrders: null,
    fetchAllOrders: async () => {},
    favoriteQuantity: 0,
    addFavorite: async () => {},
    removeFavorite: async () => {},
    updatePassword: async () => {},
    updateUserData: async () => {},
    signIn: async () => ({ success: false }),
    signOut: async () => {},
  };
}
