import Link from 'next/link'
import styles from './ProductCard.module.scss'
import { useState } from 'react'
import Strain from './Strain'
import Kind from './Kind'
import Brand from './Brand'
import FallbackImage from '../fallback-image'
import dynamic from 'next/dynamic';

import Star from '../../components/icons/star.svg';
import HalfStar from '../../components/icons/star-half.svg';
import FavoriteButton from './Favorite';

const AddToCart = dynamic(() => import('./AddToCart'));
const SaleTag = dynamic(() => import('./SaleTag'));
const Dosage = dynamic(() => import('./Dosage'));

export default function ProductCard({ hit, weight, handleClick }) {
  const [selectedWeight, setSelectedWeight] = useState(null);
  const productLink = {
    pathname: `/product/${hit.product_id}/${hit.url_slug}`,
    query: selectedWeight ? { weight: selectedWeight } : {},
  };

  return (
    <div className={styles.product}
    >
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
        <ProductImage hit={hit} productLink={productLink} />

      </div>
      <div className={styles.body}>
        <SaleTag special_amount={hit.special_amount} special_title={hit.special_title} special_id={hit.special_id} className={styles.saleTag} />
        <div className={styles.favorite}>
          <FavoriteButton productId={hit.product_id} />
        </div>

        <Link className={`${styles.name} text-ellipsis`} href={productLink} onClick={() => handleClick}>
          {hit.name.replace(
            /[\[].*?[\]]|Gummies|Sleep Gummies|- Sativa |- Indica|- Hybrid |\b\d+mg\b|\b\d+ml\b|\b\d+thc\b|\b\d+cbd\b|\(.*?(thc|mg|cbd|ml).*?\)|\|.* /gi,
            ""
          )}
        </Link>
        <Brand brand={hit.brand} />
        <Rating rating={hit.aggregate_rating} review_count={hit.review_count} />
        <div className={styles.product__info}>
          <Strain category={hit.category} />
          <Kind kind={hit.kind} kind_subtype={hit.kind_subtype} custom_product_subtype={hit.custom_product_subtype} />
          <Dosage percent_thc={hit.percent_thc} percent_cbd={hit.percent_cbd} dosage={hit.dosage} category={hit.category} />
        </div>
      </div>

      <div className={styles.product__footer}>
        <AddToCart hit={hit} weight={weight} setWeight={setSelectedWeight} className="card" />
      </div>

    </div>
  )
}

function ProductImage({ hit, productLink }) {
  const photos = hit.has_photos ? hit.photos : hit.product_photos

  return (
    <Link href={productLink} className={styles.image_container}>
      <FallbackImage
        src={photos[0]?.urls?.original || null}
        category={hit.kind}
        root_subtype={hit.root_subtype}
        lineage={hit.category}
        alt={hit.name}
        fill
        sizes="(max-width: 768px) 200px,
                    250px"
      />
    </Link>
  )
}

function Rating({ rating, review_count }) {
  if (!rating) return null;

  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <div className={styles.rating}>
      {Array(fullStars)
        .fill()
        .map((_, index) => (
          <Star key={`full-${index}`} className={`${styles.star} ${styles.filled}`} />
        ))}

      {hasHalfStar && <HalfStar key="half" className={styles.star} />}

      {Array(emptyStars)
        .fill()
        .map((_, index) => (
          <Star key={`empty-${index}`} className={`${styles.star} ${styles.empty}`} />
        ))}

      <span className={styles.reviewText}>(<span className={styles.count}>{review_count}</span>)</span>
    </div>
  );
}