import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import { useShoppingCart } from "../../context/cart.js";
import useDisableBodyScroll from "../../hooks/useDisableBodyScroll.js";
import useMediaQuery from "../../hooks/useMediaQuery.js";
import useOnClickOutside from "../../hooks/useOnClickOutside.js";

import User from "../../components/icons/user.svg";
import Heart from "../../components/icons/heart.svg";
import Search from "../../components/icons/search-light.svg";
import Hamburger from "../../components/icons/hamburger.svg";
import Bag from "../../components/icons/shopping-bag.svg";

import styles from "./Nav.module.scss";

import Location from "../../components/location/Location.js";
import MobileMenu from "./MobileMenu.js";
import Searchbar from "./Searchbar.js";
import SecondaryNav from "./SecondaryNav.js";
import Button from "../../components/Button/index.js";
import Cart from "../../components/bag";
import { useRouter } from "next/router";

import Logo from "../../public/purple-lotus-logo-black-text.svg";

import { useUser } from "../../context/user";


export default function Nav() {
  const [backdrop, setBackdrop] = useState(false);
  const isBreakPointTablet = useMediaQuery(768);
  const router = useRouter();
  const hideSecondaryNavRoutes = ["/login", "/signup", "/forgot-password", "/profile", "/product", "/privacy-policy", "/terms-of-use", "/checkout"];
  const shouldHideSecondaryNav = hideSecondaryNavRoutes.some((route) => router.pathname.startsWith(route));
  const { favoriteQuantity } = useUser();

  // console.log(favoriteQuantity);

  return (
    <>
      <nav>
        <div className={styles.wrapper}>
          <nav className={styles.navbar}>
            <div className="border-bottom relative">
              <div className={`${styles.nav} container container__slim`}>
                <ModalMenu
                  title="menu"
                  icon={<Hamburger />}
                  setBackdrop={setBackdrop}
                  className={`${styles.modalMobileMenu} ${backdrop ? styles.modalMobileMenu__active : undefined}`}
                >
                  <MobileMenu />
                </ModalMenu>

                <Link href="/" aria-label="home" className={`${styles.logo} pr-100`}>
                  <Logo />
                </Link>

                {!isBreakPointTablet && (
                  <>
                    <Searchbar />
                    <Location />
                  </>
                )}
                <div style={{ display: 'flex' }}>
                  {isBreakPointTablet && (
                    <ModalMenu
                      title="search"
                      icon={<Search />}
                      setBackdrop={setBackdrop}
                      className={styles.modalSearchMenu}
                    >
                      <Searchbar />
                    </ModalMenu>
                  )}

                  <Button
                    type="link"
                    className="icon"
                    href="/login"
                    ariaLabel="account login"
                  >
                    <User />
                  </Button>

                  <Button
                    type="link"
                    className="icon"
                    href="/favorites"
                    ariaLabel="favorite products"
                  >
                    <Heart />
                    {favoriteQuantity > 0 && (
                      <span className={styles.bagQuantity}>{favoriteQuantity}</span>
                    )}
                  </Button>

                  <ModalMenu
                    title="shopping-bag"
                    icon={<Bag />}
                    setBackdrop={setBackdrop}
                    className={styles.janeCart}
                    id="cartModal"
                  >
                    <Cart />
                  </ModalMenu>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </nav>
      {isBreakPointTablet && <Location />}
      {!shouldHideSecondaryNav && <SecondaryNav />}
    </>
  );
}

function ModalMenu(props) {
  const router = useRouter();
  const { cartQuantity, cartModal, setCartModal } = useShoppingCart();

  const isShoppingBag = props.title === "shopping-bag";
  const [localOpen, setLocalOpen] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false); // Tracks if opened at least once

  const open = isShoppingBag ? cartModal : localOpen;
  const setOpen = isShoppingBag ? setCartModal : setLocalOpen;

  useDisableBodyScroll(open);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    props.setBackdrop(open);
    if (open && !hasBeenOpened) {
      setHasBeenOpened(true); // Set flag when modal opens for the first time
    }
  }, [open, hasBeenOpened, props]);

  useEffect(() => {
    if (router.pathname === '/checkout' && isShoppingBag) {
      setOpen(true);
    }
  }, [router.pathname, isShoppingBag, setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  const modalMenuRef = useRef(null);
  useOnClickOutside(modalMenuRef, () => handleClose(), "#openModal");

  const renderChildren = () => {
    if (!open && !hasBeenOpened) return null; // Render only if open or has been opened
    return React.Children.map(props.children, (child) =>
      React.cloneElement(child, {
        open,
        handleClick,
        handleClose,
      })
    );
  };

  return (
    <div className={`${styles.modalMenu} ${props.className}`} ref={modalMenuRef}>
      <Button
        type="button"
        className="icon"
        onClick={handleClick}
        ariaLabel={props.title}
      >
        {props.icon}
        {props.title === "shopping-bag" && cartQuantity > 0 && (
          <span className={styles.bagQuantity}>{cartQuantity}</span>
        )}
      </Button>

      <div
        className={`${styles.modalMenu__modal} ${open ? styles.modalMenu__modal__active : undefined}`}
        tabIndex={-1}
        id={props.id}
      >
        <div className="relative">
          <div className={styles.modalMenu__content}>{renderChildren()}</div>
        </div>
      </div>
      <div
        className={`${styles.backdrop} ${open ? styles.backdrop_active : ''}`}
        onClick={() => setOpen(false)}
      ></div>
    </div>
  );
}

